import getConfig from "next/config";

export type RuntimeEnvs = {
  publicRuntimeConfig: {
    AMPLITUDE_API_KEY: string;
    FASTER_API_KEY: string;
    FASTER_SECRET: string;
    SENTRY_DSN: string;
    SENTRY_RELEASE: string;
    GA_TRACKING_ID: string;
    GOOGLE_RECAPTCHA_KEY: string;
    STATIC_IMAGES_URL: string;
    WSLOJA_URL: string;
    GOOGLE_MAPS_API_KEY: string;
    GOOGLE_MAPS_URL: string;
    CLEARSALE_APP_ID: string;
    CYBERSOURCE_APP_ID: string;
    GROCERIES_API_BFF_URL: string;
    POS_WAITING_PAGE_BFF_URL: string;
    POS_WAITING_PAGE_API_KEY: string;
    PRIVACY_POLICY_URL: string;
    SERVICE_TERMS_URL: string;
    PAYMENTS_MULTI_SETUP_ID: string;
    APPLICATION_KEY: string;
  };
};

export const { publicRuntimeConfig: ENVS }: RuntimeEnvs = getConfig();
