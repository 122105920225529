import '@ifood/pomodoro-fonts';

import { ChainResponse } from "@app/domains/chain/models";
import { MerchantResponse } from "@app/domains/merchant/models";
import { appAboyeur, initializeAboyeur } from "@app/domains/shared/config";
import { ErrorPage } from "@app/domains/shared/error-pages";
import { FasterRemoteConfig } from "@app/domains/shared/experiment-utils";
import { AppProps } from "next/app";
import { Component } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { setClientIP } from "@app/domains/shared/ip-utils";
import GlobalStyle from "@app/styles/global";

type AppState = {
  queryClient: QueryClient;
  gaID: string;
  serverError?: string;
  error?: Error;
};

function getGaID(
  merchantResponse?: MerchantResponse,
  chainResponse?: ChainResponse,
) {
  return (
    merchantResponse?.merchantWhitelabelConfigs.chain.gaId ||
    chainResponse?.gaId ||
    ""
  );
}

class WhitelabelApp extends Component<AppProps, AppState> {
  state: AppState = {
    queryClient: new QueryClient(),
    gaID: getGaID(
      this.props.pageProps.merchantResponse,
      this.props.pageProps.chainResponse,
    ),
    error: this.props.pageProps.serverError
      ? JSON.parse(this.props.pageProps.serverError)
      : undefined,
  };

  componentDidMount() {
    const merchantId = this.props.pageProps.merchantResponse?.uuid;
    initializeAboyeur({
      merchantId,
      googleAnalyticsId: this.state.gaID,
      isTest: false,
      merchantName: this.props.pageProps.merchantResponse?.name,
      chainId:
        this.props.pageProps.chainResponse?.uuid ||
        this.props.pageProps.merchantResponse?.merchantWhitelabelConfigs.chain
          .id,
    });
    setClientIP();
    FasterRemoteConfig.initialize();
  }

  componentDidCatch(error: Error) {
    appAboyeur.events.catch.onError(error);

    this.setState({ error });
  }

  componentDidUpdate(prevProps: AppProps) {
    if (this.props.pageProps.serverError !== prevProps.pageProps.serverError) {
      if (this.props.pageProps.serverError) {
        this.setState({ error: JSON.parse(this.props.pageProps.serverError) });
      } else {
        this.setState({ error: undefined });
      }
    }
  }

  render(): JSX.Element {
    const { Component, pageProps } = this.props;
    const hasError = Boolean(this.state.error);

    return (
      <QueryClientProvider client={this.state.queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <GlobalStyle />
          {hasError ? <ErrorPage /> : <Component {...pageProps} />}
        </Hydrate>
      </QueryClientProvider>
    );
  }
}

export default WhitelabelApp;
