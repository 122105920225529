/* eslint-disable @next/next/no-img-element */
import debounce from "lodash.debounce";
import {
  Children,
  ReactElement,
  UIEvent,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import { catalogAboyeur } from "@app/domains/catalog/events";
import * as S from "./styles";
import { CarouselItem } from "./CarouselItem";
import { ChevronRight } from "@ifood/pomodoro-icons";
import { useIsMobile } from "@app/domains/shared/hooks";

const AUTO_ROTATE_INTERVAL = 5000;

type SlideState = {
  active: number;
  left: number;
  right: number;
  last: number;
  first: number;
};
type SlideAction = { type: "next" | "previous" };

const slideReducer = (state: SlideState, action: SlideAction): SlideState => {
  switch (action.type) {
    case "next": {
      const maybeNextRightIndex = state.right + 1;
      const isOutOfRange = maybeNextRightIndex > state.last;
      const nextLeftIndex = isOutOfRange ? state.first : state.left + 1;
      const nextRightIndex = isOutOfRange ? state.first : maybeNextRightIndex;
      return {
        ...state,
        active: nextRightIndex,
        left: nextLeftIndex,
        right: nextRightIndex,
      };
    }
    case "previous": {
      const isOutOfRange = state.left - 1 < state.first;
      const nextLeftIndex = isOutOfRange ? state.last : state.left - 1;
      const nextRightIndex = isOutOfRange ? state.last : state.right - 1;
      return {
        ...state,
        active: nextLeftIndex,
        left: nextLeftIndex,
        right: nextRightIndex,
      };
    }
    default:
      return state;
  }
};

export const Carousel = ({
  simultaneousItems = 5,
  mobileWidth = "104px",
  gap = "0px",
  autoRotate = false,
  children,
}: {
  simultaneousItems?: number;
  children: ReactElement[];
  mobileWidth?: string;
  gap?: string;
  autoRotate?: boolean;
}) => {
  const [slider, slide] = useReducer(slideReducer, {
    active: 0,
    left: 0,
    right: simultaneousItems - 1,
    first: 0,
    last: children.length - 1,
  });

  const [enableAutoRotate, setEnableAutoRotate] = useState(autoRotate);
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const activeSlideRef = useRef<HTMLElement>();
  const enablePreviousButton = slider.left > 0;
  const enableNextButton = slider.right < children.length - 1;
  const isInitialMount = useRef(true);
  const isMobile = useIsMobile();

  const isInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (!activeSlideRef.current || !carouselRef.current) return;
    if (isInViewport(carouselRef.current)) {
      activeSlideRef.current.scrollIntoView({
        behavior: "smooth",
        block: isMobile ? "center" : "nearest",
        inline: isMobile ? "center" : "nearest",
      });
    }
  }, [slider.active]);

  useEffect(() => {
    if (!enableAutoRotate) return;

    const intervalId = setInterval(() => {
      slide({ type: "next" });
    }, AUTO_ROTATE_INTERVAL);

    return () => clearInterval(intervalId);
  }, [enableAutoRotate]);

  const onScrollTouchStart = () => {
    if (enableAutoRotate) {
      setEnableAutoRotate(false);
    }
  };

  const onClickPreviousBtn = () => {
    if (!enablePreviousButton) return;
    slide({ type: "previous" });
    catalogAboyeur.events.category.click("previous category button");
    setEnableAutoRotate(false);
  };

  const onClickNextBtn = () => {
    if (!enableNextButton) return;
    slide({ type: "next" });
    catalogAboyeur.events.category.click("next category button");
    setEnableAutoRotate(false);
  };

  const assignRefs = (index: number) => (ref: HTMLDivElement) => {
    activeSlideRef.current =
      index === slider.active ? ref : activeSlideRef.current;
  };

  return (
    <S.Anchor>
      <S.ScrollerWrapper ref={carouselRef}>
        <S.Scroller
          role="listbox"
          aria-label="carrosel"
          tabIndex={0}
          gap={gap}
          onTouchStart={onScrollTouchStart}
        >
          {Children.map(children, (child, index) => (
            <CarouselItem
              gap={gap}
              index={index}
              ref={assignRefs(index)}
              mobileWidth={mobileWidth}
              simultaneousItems={simultaneousItems}
            >
              {child}
            </CarouselItem>
          ))}
        </S.Scroller>
      </S.ScrollerWrapper>

      {children.length > simultaneousItems && (
        <>
          <S.NavigationLeftButton
            aria-label="Slide anterior"
            onClick={onClickPreviousBtn}
          >
            <ChevronRight
              color={enablePreviousButton ? "black" : "lightgray"}
            />
          </S.NavigationLeftButton>
          <S.NavigationRightButton
            aria-label="Próximo Slide"
            onClick={onClickNextBtn}
          >
            <ChevronRight color={enableNextButton ? "black" : "lightgray"} />
          </S.NavigationRightButton>
        </>
      )}
    </S.Anchor>
  );
};
