import axios from "axios";

const IP_STORAGE_KEY = "wl.user_ip";

export const fetchClientIP = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data.ip;
  } catch (error) {
    return null;
  }
};

export const setClientIP = async () => {
  const storedIP = sessionStorage.getItem(IP_STORAGE_KEY);
  if (storedIP) return;

  const userIP = await fetchClientIP();
  if (userIP) {
    sessionStorage.setItem(IP_STORAGE_KEY, userIP);
  }
};

export const getClientIp = () => {
  const storedIP = sessionStorage.getItem(IP_STORAGE_KEY);
  return storedIP ?? "0.0.0.0";
};
