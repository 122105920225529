import RemoteConfig from "@ifood/faster-remote-config";
import {
  ENVS,
  getDeviceId,
  getFasterInstance,
  getIsDevelopment,
  getIsTest,
} from "@app/domains/shared/config";

import pkg from "../../../../../../../package.json";
import { FASTER_REMOTE_CONFIG_SCHEMAS } from "../constants";

export type PropertyValue = number | string | boolean;
export type SchemaValues = {
  [propertyName: string]: PropertyValue;
};

export declare type SchemasValues = {
  [schemaName: string]: SchemaValues;
};

export class FasterRemoteConfig {
  static instance: RemoteConfig;

  static initialize() {
    if (getIsDevelopment() || getIsTest()) return;

    FasterRemoteConfig.instance = RemoteConfig.start({
      appKey: ENVS.FASTER_API_KEY,
      appVersion: pkg.version,
    });

    FasterRemoteConfig.instance.setDeviceId(getDeviceId());
    FasterRemoteConfig.instance.setAnalyticsSender(getFasterInstance());
  }

  static async getSchemaValue(
    schemaName: string,
  ): Promise<SchemaValues | undefined> {
    if (!FasterRemoteConfig.instance) return undefined;

    const schemaValues = FasterRemoteConfig.instance.getSchema(schemaName);

    if (schemaValues) return schemaValues;

    await FasterRemoteConfig.instance.fetchSchemas(
      Array.from(Object.values(FASTER_REMOTE_CONFIG_SCHEMAS)),
    );
    return FasterRemoteConfig.instance.getSchema(schemaName);
  }
}
