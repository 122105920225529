import { PomodoroProvider } from "@ifood/pomodoro-components";
import { ChainResponse } from "@app/domains/chain/models";
import { MerchantResponse } from "@app/domains/merchant/models";
import {
  generateChainManifestURL,
  generateMerchantManifestURL,
} from "@app/domains/merchant/utils";
import { appAboyeur } from "@app/domains/shared/config";
import { CHAIN_LOGO_URL } from "@app/domains/shared/constants";
import {
  ColorsTheme,
  createVariations,
  neutralTheme,
} from "@app/domains/shared/design-system";
import { EmptyProps } from "@app/domains/shared/types";
import {
  getMerchantIdFromUrl,
  getQueryParams,
} from "@app/domains/shared/url-utils";
import dynamic from "next/dynamic";
import Head from "next/head";
import Script from "next/script";
import { useEffect, useMemo } from "react";
import { CanaryProvider } from "../CanaryProvider";
import { capitalizeFirstLetter } from "@app/domains/shared/string-utils";
import { Main } from "./styles";

const CanaryFlag = dynamic<EmptyProps>(() =>
  import("../CanaryFlag").then(({ CanaryFlag }) => CanaryFlag),
);

function getColorsTheme(
  defaultColors: ColorsTheme,
  chain?: ChainResponse,
  merchant?: MerchantResponse,
): ColorsTheme {
  if (chain) {
    return {
      primary: chain.primaryColor || defaultColors.primary,
      headings: chain.primaryColor || defaultColors.primary,
    };
  }

  if (merchant) {
    return {
      primary:
        merchant.merchantWhitelabelConfigs.chain.primaryColor ||
        defaultColors.primary,
      headings:
        merchant.merchantWhitelabelConfigs.chain.primaryColor ||
        defaultColors.headings,
    };
  }

  return defaultColors;
}

function configureStorage() {
  const versionHash = "0d93a447-1a38-4867-93e9-94cb0fd8a275";

  if (!localStorage) return;

  if (localStorage.getItem("versionHash") !== versionHash) {
    localStorage.clear();
    localStorage.setItem("versionHash", versionHash);
  }
}

export type PageVariant = "primary" | "secondary";

type PageProps = {
  title: string;
  name: string;
  imageUrl?: string;
  description?: string;
  chainResponse?: ChainResponse;
  merchantResponse?: MerchantResponse;
  merchantPath?: string;
  noIndex?: boolean;
  isCanaryModeEnabled?: boolean;
  variant?: PageVariant;
};

export const Page: React.FC<PageProps> = ({
  children,
  title,
  name,
  imageUrl,
  description,
  chainResponse,
  merchantResponse,
  merchantPath,
  noIndex = false,
  isCanaryModeEnabled = false,
  variant = "primary",
}) => {
  const manifestURL = useMemo(() => {
    if (!chainResponse && !merchantResponse) return;

    if (!merchantResponse)
      return generateChainManifestURL(chainResponse as ChainResponse);

    return generateMerchantManifestURL(merchantResponse, merchantPath);
  }, [chainResponse, merchantResponse, merchantPath]);

  const theme = useMemo(() => {
    const defaultColorsTheme = {
      primary: (neutralTheme.colors as Record<string, string>)["primary"],
      headings: (neutralTheme.colors as Record<string, string>)["primary"],
    };

    const fontsTheme = {
      // @ts-ignore
      headings: (neutralTheme.font.family.ifood as Record<string, string>)[
        "headings"
      ],
      // @ts-ignore
      body: (neutralTheme.font.family.ifood as Record<string, string>)["body"],
    };

    return createVariations(
      fontsTheme,
      getColorsTheme(defaultColorsTheme, chainResponse, merchantResponse),
    );
  }, [chainResponse, merchantResponse]);

  const logoUrl = useMemo(() => {
    if (!chainResponse && !merchantResponse) return "";

    if (chainResponse) {
      const logoPath = chainResponse.logoUrl;
      if (!logoPath) return "";
      return `${CHAIN_LOGO_URL}/${logoPath}`;
    }

    if (merchantResponse) {
      const logoPath = merchantResponse.merchantWhitelabelConfigs.chain.logoUrl;
      if (!logoPath) return "";
      return `${CHAIN_LOGO_URL}/${logoPath}`;
    }

    return "";
  }, [chainResponse, merchantResponse]);

  const pageTitle = useMemo(() => {
    if (!chainResponse && !merchantResponse) return title;

    if (chainResponse)
      return `${
        chainResponse.name ?? capitalizeFirstLetter(chainResponse.slug)
      } - ${title}`;

    return `${title ?? "Indisponível"} - ${merchantResponse?.name}`;
  }, [title, chainResponse, merchantResponse]);

  useEffect(() => {
    appAboyeur.events.pages.view({
      page: name,
      page_url: window.location.href,
      merchant_id: getMerchantIdFromUrl(),
      coupon: getQueryParams().get("coupon") ?? undefined,
    });

    configureStorage();
  }, [name]);

  return (
    <PomodoroProvider theme={theme}>
      <Head>
        <title>{pageTitle}</title>
        {manifestURL && <link rel="manifest" href={manifestURL} />}
        {logoUrl && (
          <>
            <link rel="icon" type="image/png" href={logoUrl} />
            <link rel="apple-touch-icon" href={logoUrl} />
            <meta property="og:image" content={imageUrl ?? logoUrl} />
          </>
        )}
        {description && (
          <meta
            name="description"
            property="og:description"
            content={description}
          />
        )}
        <meta name="theme-color" content="white" />
        <meta property="og:title" content={pageTitle} />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <meta charSet="UTF-8" />
        {noIndex && <meta name="robots" content="noindex" />}
        <meta
          name="google-site-verification"
          content="swwAKwbGgDLmgv4NOhTxiJOvPctMSDTv2tQZOft0olE"
        />
        <meta
          name="google-site-verification"
          content="JYChscDkhUwFF0Yn3jvJ7BYq1HBd6t1LaQS1jM7vITY"
        />
        <meta
          name="facebook-domain-verification"
          content="ajrozxqb8bp38lowjpb4tmd26b5uta"
        />
      </Head>

      {isCanaryModeEnabled && <CanaryFlag />}

      <CanaryProvider isCanaryModeEnabled={isCanaryModeEnabled}>
        <Main variant={variant}>{children}</Main>
      </CanaryProvider>
    </PomodoroProvider>
  );
};
