import * as S from "./styles";
import NextImage from "next/image";
import { useMemo } from "react";
import Link from "next/link";
import {
  getBrandImageURL,
  getSocialMediaIcons,
} from "@app/domains/shared/image-utils";
import { LinkChain } from "@app/domains/chain/models";
import { PaymentMethods } from "@app/domains/checkout/models";
import { Merchant } from "@app/domains/merchant/models";
import { ENVS } from "@app/domains/shared/config";
import { useRouter } from "next/router";

type PageFooterProps = {
  socialMediaLinks?: LinkChain[];
  institutionalLinks?: LinkChain[];
  paymentMethods?: PaymentMethods;
  merchant?: Merchant;
};

export const PageFooter: React.VFC<PageFooterProps> = ({
  socialMediaLinks,
  institutionalLinks,
  paymentMethods,
  merchant,
}) => {
  const router = useRouter();

  const hideOnMobile = useMemo(() => {
    const pathsToHide = ["/order", "/excluir-conta", "/busca"];
    return pathsToHide.some((route) => router.asPath.includes(route));
  }, [router.asPath]);

  const payments = useMemo(() => {
    const seenUrls = new Set();
    return paymentMethods?.list.filter((paymentMethod) => {
      const brandImageUrl = getBrandImageURL(paymentMethod);
      if (brandImageUrl.includes("default")) return false;
      if (seenUrls.has(brandImageUrl)) return false;
      seenUrls.add(brandImageUrl);
      return true;
    });
  }, [paymentMethods]);

  const metadata = merchant?.metadata as Record<string, string | undefined>;
  const pharmacyInfo = merchant?.isPharmacy()
    ? [
        { key: "pharmacist_name", label: "Pessoa farmacêutica responsável" },
        { key: "crf", label: "CRF" },
        { key: "operating_authorization", label: "AFE" },
        { key: "sanitary_permit", label: "CMVS" },
        { key: "sanitary_permit_expiration", label: "Vencimento" },
        { key: "special_authorization", label: "AE" },
        { key: "special_authorization_expiration", label: "Vencimento" },
      ]
        .map(({ key, label }) => {
          return metadata[key] && `${label}: ${metadata[key]}`;
        })
        .filter(Boolean)
    : [];

  return (
    <S.Footer hideOnMobile={hideOnMobile}>
      {(Boolean(institutionalLinks?.length) ||
        Boolean(socialMediaLinks?.length) ||
        merchant ||
        payments) && (
        <>
          <S.TopFooter>
            <S.Content>
              {Boolean(institutionalLinks?.length) && (
                <S.Column>
                  <S.Title>Institucional</S.Title>
                  {institutionalLinks?.map((link) => (
                    <Link key={link.id} href={link.url}>
                      <S.Info>{link.title}</S.Info>
                    </Link>
                  ))}
                </S.Column>
              )}

              {Boolean(socialMediaLinks?.length) && (
                <S.Column>
                  <S.Title>Redes sociais</S.Title>
                  <S.SocialMediaGroupColumn>
                    {socialMediaLinks?.map((link) => (
                      <Link key={link.id} href={link.url}>
                        <S.SocialMediaIcon
                          onClick={() => (window.location.href = link.url)}
                        >
                          <NextImage
                            src={getSocialMediaIcons(link.title)}
                            width={24}
                            height={24}
                          />
                        </S.SocialMediaIcon>
                      </Link>
                    ))}
                  </S.SocialMediaGroupColumn>
                </S.Column>
              )}

              {merchant && (
                <S.Column>
                  <S.Title>Precisa de ajuda?</S.Title>
                  <S.Info>+55 {merchant.phone}</S.Info>
                  {merchant.getFAQLink() && (
                    <Link href={merchant.getFAQLink()}>
                      <S.Info>FAQ</S.Info>
                    </Link>
                  )}
                </S.Column>
              )}

              {payments && (
                <S.Column>
                  <S.Title>Formas de pagamento</S.Title>
                  <S.Payments>
                    {payments.map((payment) => (
                      <NextImage
                        key={payment.id}
                        height={22}
                        width={32}
                        src={getBrandImageURL(payment)}
                        alt={payment.name}
                      />
                    ))}
                  </S.Payments>
                </S.Column>
              )}
            </S.Content>
          </S.TopFooter>
          <S.Divider />
        </>
      )}

      <S.BottomFooter>
        <S.Content>
          <S.Column>
            <S.Row>
              <S.PoweredBy>powered by</S.PoweredBy>
              <NextImage
                height={40}
                width={50}
                src="/icons/ifood-smiley.svg"
                alt="iFood logo"
              ></NextImage>
              <S.Copyright>
                © Copyright 2024 - iFood - Todos os direitos reservados iFood
                com Agência de Restaurantes Online S.A.
              </S.Copyright>
            </S.Row>
          </S.Column>

          <S.LgpdLinks>
            <a
              href={ENVS.SERVICE_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.SmallInfo>Termos e condições de uso</S.SmallInfo>
            </a>
            <a
              href={ENVS.PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.SmallInfo>Declaração de privacidade</S.SmallInfo>
            </a>
          </S.LgpdLinks>
        </S.Content>

        {Boolean(pharmacyInfo.length) && (
          <S.PharmaInfo>{pharmacyInfo.join(" | ")}</S.PharmaInfo>
        )}
      </S.BottomFooter>
    </S.Footer>
  );
};
