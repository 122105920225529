import styled from "styled-components";

import { BaseDrawer as PomodoroBaseDrawer } from "../BaseDrawer";

const bellowDesktop = ({ theme }: { theme: any }) =>
  `max-width: ${theme.screens.l.min}`;

export const Header = styled(PomodoroBaseDrawer.Header)`
  width: 100%;
  text-align: center;
  padding-top: 17px;
  padding-bottom: 17px;
  background-color: ${({ theme }) => theme.colors.primary};

  & h1 {
    max-width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: auto;
  }

  @media (${bellowDesktop}) {
    padding: ${({ theme }) => theme.space.regular};
    background-color: white;
    z-index: 1;

    & h1 {
      color: ${({ theme }) => theme.colors.black};
      font-size: ${({ theme }) => theme.fontSizes.s};
      line-height: ${({ theme }) => theme.fontSizes.s};
      text-transform: uppercase;
      font-weight: 500;
    }

    & button {
      top: 0px;
      padding: 0;
      width: auto;
    }

    & svg {
      color: ${({ theme }) => theme.colors.primary};
      //transform: rotate(-90deg);
    }
  }
`;
