import { SellingOption } from "@app/domains/catalog/models";

export function formatQuantity(value: number, sellingOption?: SellingOption) {
  if (!sellingOption?.incremental) return value;
  let calcValue = value * sellingOption.incremental;
  let unit = "g";
  if (calcValue >= 1000) {
    calcValue = calcValue / 1000;
    unit = "kg";
  }
  return `${calcValue}${unit}`;
}
