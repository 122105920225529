import { appAboyeur } from "@app/domains/shared/config";
import { Page } from "@app/domains/shared/page-wrapper";
import {
  getMerchantIdFromUrl,
  getQueryParams,
} from "@app/domains/shared/url-utils";
import { useEffect } from "react";
import SVG from "react-inlinesvg";

import * as S from "./styles";

export const NotFound = () => {
  useEffect(() => {
    appAboyeur.events.pages.view({
      page: "not_found",
      page_url: window.location.href,
      merchant_id: getMerchantIdFromUrl(),
      coupon: getQueryParams().get("coupon") ?? undefined,
    });
  }, []);

  return (
    <Page title="Loja não encontrada" name="notFound" noIndex>
      <S.EmptyState
        title="Loja não encontrada"
        description="Verifique novamente o link divulgado."
      >
        <SVG src="/images/not_found.svg" width={291} height={286} />
      </S.EmptyState>
    </Page>
  );
};
