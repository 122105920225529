import { Icon } from "@ifood/pomodoro-components";
import { ChevronLeft } from "@ifood/pomodoro-icons";

import * as S from "./styles";
import { useIsMobile } from "@app/domains/shared/hooks";

type BackButtonProps = {
  handleClick: () => void;
  arialLabel?: string;
};

export const BackButton: React.VFC<BackButtonProps> = ({
  handleClick,
  arialLabel = "Fechar",
  ...otherProps
}) => {
  const isMobile = useIsMobile();

  return (
    <S.Button onClick={handleClick} aria-label={arialLabel} {...otherProps}>
      <Icon component={ChevronLeft} size={isMobile ? "s" : "m"} />
    </S.Button>
  );
};
