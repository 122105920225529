import { Plus } from "@ifood/pomodoro-icons";
import { Minus } from "@ifood/pomodoro-icons";
import * as S from "./styles";
import { formatQuantity } from "../../utils/formatQuantity";
import { QuickAddVariantProps } from "../types";

export const SmallQuickAdd: React.FC<QuickAddVariantProps> = ({
  value,
  description,
  sellingOption,
  handleIncrementClick,
  handleDecrementClick,
  isEmpty,
}) => (
  <S.QuickAddContainer>
    {!isEmpty && (
      <S.CustomIcon
        component={Minus}
        height="28"
        width="28"
        color="primary"
        onClick={handleDecrementClick}
        aria-label="Diminuir quantidade"
        role="button"
      />
    )}

    {!isEmpty && (
      <S.Value isWeightVariable={Boolean(sellingOption)}>
        {formatQuantity(value, sellingOption)}
      </S.Value>
    )}

    <S.CustomIcon
      component={Plus}
      height="28"
      width="28"
      onClick={handleIncrementClick}
      aria-label={isEmpty ? `Adicionar ${description}` : "Aumentar quantidade"}
      role="button"
    />
  </S.QuickAddContainer>
);
