import { Z_INDEXES } from "@app/domains/shared/constants";

import { BackButton } from "../BackButton";
import { BaseDrawer, BaseDrawerProps } from "../BaseDrawer";
import { Heading } from "@ifood/pomodoro-components";
import * as S from "./styles";

type DrawerCompositionElements = {
  Body: typeof BaseDrawer.Body;
  Footer: typeof BaseDrawer.Footer;
};

type DrawerProps = BaseDrawerProps & {
  title: string;
  onClose?: () => void;
};

type DrawerComposition = React.FC<DrawerProps> & DrawerCompositionElements;

export const Drawer: DrawerComposition = ({
  open,
  onClose,
  children,
  title,
  zIndex,
  ...otherProps
}) => (
  <BaseDrawer
    open={open}
    onClose={onClose}
    placement="right"
    zIndex={zIndex ?? Z_INDEXES.DEFAULT_DRAWER}
    {...otherProps}
  >
    {(title || onClose) && (
      <S.Header>
        {onClose && <BackButton handleClick={onClose} />}
        <Heading my={0} fontSize="24px" as="h1" fontWeight="400" color="white">
          {title}
        </Heading>
      </S.Header>
    )}
    {children}
  </BaseDrawer>
);

Drawer.Body = BaseDrawer.Body;
Drawer.Footer = BaseDrawer.Footer;
