import { Drawer as PomodoroDrawer } from '@ifood/pomodoro-components';
import styled from 'styled-components';

export const Drawer = styled(PomodoroDrawer)`
  height: unset;

  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.radii.m};

  &:focus-visible {
    outline: none;
  }

  & div > svg {
    display: none;
  }

  @media (min-width: 768px) {
    --width: 548px;
    --height: 250px;
    max-width: var(--width);
    min-height: var(--height);
    transform: translate(
      calc((50vw - var(--width) / 2) * -1),
      calc((50vh - var(--height) / 2) * -1)
    ) !important;
  }
`;

export const Header = styled(PomodoroDrawer.Header)`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  @media screen and (max-width: 767px) {
    padding: 0px 16px;
  }
`;

export const Body = styled(PomodoroDrawer.Body)`
  @media screen and (max-width: 767px) {
    padding: 0px 16px;
  }
`;

export const Footer = styled(PomodoroDrawer.Footer)`
  @media screen and (max-width: 767px) {
    padding: 0px 16px 16px 16px;
  }
`;
