import { ENVS } from "@app/domains/shared/config";

import { grecaptcha } from "./types";

declare global {
  interface Window {
    grecaptcha: grecaptcha;
  }
}

export class Recaptcha {
  static async getRecaptcha(action: string) {
    if (!window || !window.grecaptcha) return undefined;

    const siteKey = ENVS.GOOGLE_RECAPTCHA_KEY;
    const grecaptchaInstance = window.grecaptcha;

    try {
      const recaptchaToken: string =
        await grecaptchaInstance.enterprise.execute(siteKey, {
          action,
        });

      return recaptchaToken;
    } catch (e: any) {
      return undefined;
    }
  }
}
