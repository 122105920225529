import styled from "styled-components";
import { PageVariant } from "./Page";

const VARIANT_BG_COLOR = {
  primary: "white",
  secondary: "#f7f7f7",
};

const VARIANT_FOOTER_COLOR = {
  primary: "#f7f7f7",
  secondary: "white",
};

export const Main = styled.main<{ variant: PageVariant }>`
  min-height: 100dvh;
  height: auto;
  display: flex;
  flex-direction: column;
  background: ${({ variant }) => VARIANT_BG_COLOR[variant]};

  footer {
    background-color: ${({ variant }) => VARIANT_FOOTER_COLOR[variant]};
  }
`;
