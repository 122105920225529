import { Plus } from "@ifood/pomodoro-icons";
import { Minus } from "@ifood/pomodoro-icons";
import * as S from "./styles";
import { formatQuantity } from "../../utils/formatQuantity";
import { QuickAddVariantProps } from "../types";

export const LargeQuickAdd: React.FC<QuickAddVariantProps> = ({
  value,
  label,
  description,
  sellingOption,
  handleIncrementClick,
  handleDecrementClick,
  isEmpty,
}) => (
  <S.QuickAddContainer isEmpty={isEmpty}>
    <S.Label
      isEmpty={isEmpty}
      value={value}
      onClick={handleIncrementClick}
      aria-label={`Adicionar ${description}`}
      role="button"
    >
      {label ?? "Adicionar"}
    </S.Label>
    <S.MinusIcon
      component={Minus}
      isEmpty={isEmpty}
      height="28"
      width="28"
      color="primary"
      onClick={handleDecrementClick}
      aria-label="Diminuir quantidade"
      role="button"
    />
    <S.Value isEmpty={isEmpty}>{formatQuantity(value, sellingOption)}</S.Value>
    <S.PlusIcon
      component={Plus}
      isEmpty={isEmpty}
      height="28"
      width="28"
      onClick={handleIncrementClick}
      aria-label="Aumentar quantidade"
      role="button"
    />
  </S.QuickAddContainer>
);
